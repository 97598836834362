<template>
  <div class="page-max page-start" style="flex-direction: column">
    <div>
      <h2>ФИАЦ</h2>
    </div>
    <div class="list">
      <!--<div v-for="user in users" :key="user.id" class="list-user">-->
      <!--{{user.lastName}} {{user.name}}-->
      <!--</div>-->
      <div class="row" style="padding-bottom: 5px">
        <!--<div class="col-sm-12 col-md-2 ">-->
        <!--<div class="dataTables_length bs-select" id="dtBasicExample_length">-->
        <!--<label>Показать по <select name="dtBasicExample_length" aria-controls="dtBasicExample" class="custom-select custom-select-sm form-control form-control-sm" v-model="currentPageLimit" :options="pageLimits">-->
        <!--<option v-for="limit in pageLimits" v-bind:value="limit" :key="limit">-->
        <!--{{ limit }}-->
        <!--</option>-->
        <!--</select></label></div>-->
        <!--</div>-->
        <div class="col-sm-4 col-xs-12 text-left">
          <div class="dataTables_length bs-select" id="userTypeSelector" style="width: 90%">
            <label>Показать <select name="dtBasicExample_length" aria-controls="dtBasicExample" class="custom-select custom-select-sm form-control form-control-sm" v-model="userType" :options="userTypes"  style="width: 100%">
              <option v-for="type in userTypes" v-bind:value="type.value" :key="type.value">
                {{ type.label }}
              </option>
            </select></label>
          </div>
        </div>
        <div class="col-sm-8 col-xs-12 text-right left-on-small">
          <div id="dtBasicExample_filter" class="dataTables_filter" >
            <label ><span style="float: left; padding-left: 10px">Искать:</span>
              <input type="search" class="form-control form-control-sm" placeholder="" aria-controls="dtBasicExample" v-model="filter" ></label>
          </div>
        </div>
      </div>
      <div style="overflow-x: auto; max-width: 98vw">
        <table id="dtBasicExample" class="table table-striped table-bordered table-sm" cellspacing="0" width="100%">
          <thead>
          <tr>
            <th class="th-sm" style="width: 100px">Фамилия
            </th>
            <th class="th-sm" style="width: 100px">Имя
            </th>
            <th class="th-sm" style="width: 150px">Отчество
            </th>
            <th class="th-sm" style="min-width: 200px">Почта
            </th>
            <th class="th-sm" style="width: 100px">Телефон
            </th>
            <th class="th-sm" style="width: 150px">Роль
            </th>
            <th class="th-sm" style="width: 100px">Действия
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="user in filteredUsers" :key="user.id">

            <td>{{user.lastName}}</td>
            <td>{{user.firstName}}</td>
            <td>{{user.patronymic}}</td>
            <td  style="word-break: break-all; word-wrap: normal">{{user.email}}</td>
            <td>{{user.phone}}</td>
            <td>
              <div v-for="role in user.roles" :key="role.type">
                {{getText(role.type)}}
              </div>
            </td>
            <td style="display: flex; gap: 10px; justify-content: stretch; flex-wrap: wrap">
              <span v-if="user.activated" @click="alertx('Операция не поддерживается')" style="text-decoration: underline; cursor: pointer" class="text-danger">Удалить</span>
              <span v-if="user.activated" @click="reset(user)" style="text-decoration: underline; cursor: pointer" class="text-danger">Сбросить пароль</span>
              <!--<span v-if="user.activated" @click="checkLogs(user)" style="text-decoration: underline; cursor: pointer">Логи</span>-->

              <span v-if="!user.activated" @click="approveUser(user)" style="text-decoration: underline; cursor: pointer">Активировать</span>

              <span v-if="!user.activated" @click="rejectUser(user)" style="text-decoration: underline; cursor: pointer">Отклонить</span>

            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr v-if="filteredUsers.length">
            <th>Фамилия
            </th>
            <th>Имя
            </th>
            <th>Отчество
            </th>
            <th>Почта
            </th>
            <th>Телефон
            </th>
            <th>Роль
            </th>
            <th>Действия
            </th>
          </tr>
          </tfoot>
        </table>
      </div>
      <!--<div class="row">-->
      <!--<div class="col-sm-12 col-md-8">-->
      <!--&lt;!&ndash;<div class="dataTables_info" id="dtBasicExample_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>&ndash;&gt;-->
      <!--</div><div class="col-sm-12 col-md-4  text-right">-->
      <!--<div class="dataTables_paginate paging_simple_numbers" id="dtBasicExample_paginate">-->
      <!--<ul class="pagination">-->
      <!--<li class="paginate_button page-item previous disabled" id="dtBasicExample_previous">-->
      <!--<a href="#" aria-controls="dtBasicExample" data-dt-idx="0" tabindex="0" class="page-link">предыдущие</a></li>-->
      <!--<li class="paginate_button page-item active"><a href="#" aria-controls="dtBasicExample" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>-->
      <!--<li class="paginate_button page-item "><a href="#" aria-controls="dtBasicExample" data-dt-idx="2" tabindex="0" class="page-link">2</a></li>-->
      <!--<li class="paginate_button page-item "><a href="#" aria-controls="dtBasicExample" data-dt-idx="3" tabindex="0" class="page-link">3</a></li>-->
      <!--<li class="paginate_button page-item "><a href="#" aria-controls="dtBasicExample" data-dt-idx="4" tabindex="0" class="page-link">4</a></li>-->
      <!--<li class="paginate_button page-item "><a href="#" aria-controls="dtBasicExample" data-dt-idx="5" tabindex="0" class="page-link">5</a></li>-->
      <!--<li class="paginate_button page-item "><a href="#" aria-controls="dtBasicExample" data-dt-idx="6" tabindex="0" class="page-link">6</a></li>-->
      <!--<li class="paginate_button page-item next" id="dtBasicExample_next">-->
      <!--<a href="#" aria-controls="dtBasicExample" data-dt-idx="7" tabindex="0" class="page-link">Следущие</a></li>-->
      <!--</ul></div></div>-->
      <!--</div>-->
    </div>
  </div>
</template>

<script>
  import {getUsers} from "../api/user-controller";
  import {approveRegisterRequest, getRequests, rejectRegisterRequest} from "../api/request-controller";
  import logger from '../utils/logger'
  import {fieldSorter} from "../utils/compare";
  import {translate} from "../utils/translations";

  import {mapActions} from 'vuex'



  export default {
    name: "Users",
    data() {
      return {
        filter: '',
        users: [],
        userType: (this.$route.query && this.$route.query.type === 'requests') ? 'requests' : 'all',
        userTypes: [
          {value: 'all', label: 'Все'},
          {value: 'requests', label: 'Запросы'},
          {value: 'users', label: 'Пользователи'}
        ],
        pageLimits: [10, 25, 100],
        currentPageLimit: 10,
        demoUsers: [
          {id: 1, organization: 1, role: 1, name: 'Игорь', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101231212', email: 'ivanov.igor@noexistentemail.null', activated: true},
          {id: 2, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 3, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 4, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 5, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 6, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 7, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 8, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
          {id: 9, organization: 1, role: 1, name: 'Матвей', lastName: 'Иванов', patronymic: 'Петрович',
            phone: '+79101233212', email: 'ivanov.matvey@noexistentemail.null', activated: false},
        ]
      }
    },
    computed: {
      filteredUsers() {
        if (!this.filter || !this.filter.trim()) return this.users.filter(this.typeEqual)//this.users
        return this.users.filter(u => {
          return this.typeEqual(u) && Object.values(u).some(v => {
            if (v) {
              logger('v', v, typeof v === 'string', typeof v)
              if (typeof v === 'string') {
                return v.startsWith(this.filter)
              } else {
                return false
              }
            } else {
              return false
            }
          })
        })
      }
    },
    methods: {
      ...mapActions(['showInfoAlert']),
      // checkLogs(user) {
      //   this.$router.push('/logs/' + user.id)
      // },
      getText(text) {
        return translate(text)
      },
      reset(user) {

        logger('user', user)

        this.showInfoAlert('пока не поддерживается')
        // resetPassword(user.email ? user.email : null)
        //   .then((result) => {
        //     logger('result', result)
        //     this.showInfoAlert('пароль сброшен')
        //     return this.init()
        //   })
        //   .catch(e => this.showInfoAlert(e))
      },
      typeEqual(user) {
        switch (this.userType) {
          case 'users' : return user.activated
          case 'requests' : return !user.activated
          default: return true
        }
      },
      alertx(text) {
        this.showInfoAlert(text)
      },
      async rejectUser(user) {
        logger('user', user)
        rejectRegisterRequest(user.email ? user.email : null)
          .then((result) => {
            logger('result', result)
            return this.init()
          })
          .catch(e => this.showInfoAlert(e))
      },
      async approveUser(user) {
        logger('user', user)
        approveRegisterRequest(user.email ? user.email : null)
          .then((result) => {
            logger('result', result)
            return this.init()
          })
          .catch(e => this.showInfoAlert(e))
      },
      // async init() {
      //   let users = getUsers()
      //   let requests = getRequests()
      //   Promise.all([users, requests])
      //     .then(async results => {
      //       let userData = await users
      //       let requestData = await requests
      //       logger('results', results, 'users', userData.payload, 'requests', requestData.payload)
      //       let data = []
      //       userData.payload?.forEach(u => {
      //         Object.assign(u, splitFullname(u.fullName), {activated: true})
      //         data.push(u)
      //       })
      //       requestData.payload?.forEach(r => {
      //         Object.assign(r, splitFullname(r.fullName))
      //         data.push(r)
      //       })
      //       data.sort(fieldSorter(['lastName', 'name', 'patronymic', 'username', 'created']))
      //       logger('data', data)
      //       this.users = data
      //     })
      //     .catch(e => {
      //       this.showInfoAlert(e)
      //     })
      // },
      async init() {
        logger('init route', this.$route.query, this.$route.query.type, this.$route.query.type === 'requests', this.userType)
        if (this.$route.query && this.$route.query.type === 'requests ') {
          this.userType = 'requests'
          this.userType.$touch
        }
        logger('init route', this.$route.query, this.$route.query.type, this.userType)
        let users = getUsers()
        let requests = getRequests()
        return Promise.all([users, requests])
          .then(async results => {
            let userData = await users
            let requestData = await requests
            logger('results', results, 'users', userData.payload, 'requests', requestData.payload)
            let data = []
            userData.payload?.forEach(u => {
              Object.assign(u, {activated: true})
              data.push(u)
            })
            requestData.payload?.forEach(r => {
              // Object.assign(r, splitFullname(r.fullName))
              data.push(r)
            })
            data.sort(fieldSorter(['lastName', 'firstName', 'patronymic', 'username', 'created']))
            logger('data', data)
            this.users = data
          })
          .catch(e => {
            this.showInfoAlert(e)
          })
      }
    },
    mounted() {
      this.init()
    }
  }
</script>

<style scoped>
  .list {
    padding: 20px;
  }
  .list-user {
    padding: 5px;
    height: 40px;
    background-color: aliceblue;
  }

  .list-user + .list-user {
    background-color: lightsteelblue;
  }

  @media screen and (max-width: 768px) {
    .left-on-small {
      text-align: left;
      float: left;
    }
  }
</style>
